import { styled } from "@mui/system";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";

interface Props {
  firstInitial?: string;
  lastInitial?: string;
}

function UserAvatar({ firstInitial, lastInitial }: Props) {
  const StyledUserIcon = styled("div")({
    backgroundColor: "lightgrey",
    fontSize: 15,
    marginLeft: 10,
    borderRadius: 30,
    padding: "3px 5px 3px 5px",
    alignContent: "center",
  });

  const StyledInitialsAvatar = styled("div")({
    backgroundColor: "lightgrey",
    fontSize: 15,
    marginLeft: 10,
    borderRadius: 20,
    padding: 7,
    alignContent: "center",
  });

  return lastInitial && firstInitial ? (
    <StyledInitialsAvatar>{firstInitial + lastInitial}</StyledInitialsAvatar>
  ) : (
    <StyledUserIcon>
      <PersonRoundedIcon />
    </StyledUserIcon>
  );
}

export default UserAvatar;
