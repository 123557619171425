import { lazy } from "react";
import { Outlet } from "react-router-dom";

import Loadable from "ui-component/Loadable";

const EpisodePage = Loadable(lazy(() => import("views/episodes/Episode")));

// ==============================|| PODCAST ROUTING ||============================== //
const EpisodeContainer = <Outlet />;

const EpisodeRoutes = {
  path: "episodes",
  element: EpisodeContainer,
  children: [
    {
      path: ":id",
      element: <EpisodePage />,
    },
  ],
};

export default EpisodeRoutes;
