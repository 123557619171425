import React from "react";

// material-ui
import { Divider, List, Typography } from "@mui/material";

// project imports
import { GenericCardProps } from "types";
import NavItem from "../NavItem";
// eslint-disable-next-line import/no-cycle
import NavCollapse from "../NavCollapse";

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

export interface NavGroupProps {
  item: {
    id?: string;
    type?: string;
    icon?: GenericCardProps["iconPrimary"];
    children?: NavGroupProps["item"][];
    title?: React.ReactNode | string;
    caption?: React.ReactNode | string;
    color?: "primary" | "secondary" | "default" | undefined;
  };
}

function NavGroup({ item }: NavGroupProps) {
  // menu list collapse & items
  const items = item.children?.map((menu) => {
    switch (menu.type) {
      case "collapse":
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case "item":
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography variant="caption" display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
}

export default NavGroup;
