import { lazy } from "react";

import Loadable from "ui-component/Loadable";

// sample page routing
const ResourcesPage = Loadable(lazy(() => import("views/resources/Resources")));

// ==============================|| RESOURCES ROUTING ||============================== //

const ResourcesRoutes = {
  path: "resources",
  element: <ResourcesPage />,
  children: [],
};

export default ResourcesRoutes;
