import { createStore } from "redux";
import reducer from "./reducer";

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(
  reducer,
  {},
  // eslint-disable-next-line no-underscore-dangle
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    // eslint-disable-next-line no-underscore-dangle
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

// eslint-disable-next-line import/prefer-default-export
export { store };
