import { lazy } from "react";

import Loadable from "ui-component/Loadable";
import { Outlet } from "react-router-dom";

// sample page routing
const SettingsPage = Loadable(
  lazy(() => import("views/settings/components/SettingsPage"))
);
const ChangePassword = Loadable(
  lazy(() => import("views/settings/forms/ChangePassword"))
);

// ==============================|| SETTINGS ROUTING ||============================== //
const SettingsContainer = <Outlet />;

const SettingsRoutes = {
  path: "settings",
  element: SettingsContainer,
  children: [
    {
      path: "",
      element: <SettingsPage />,
    },
    {
      path: "change-password",
      element: <ChangePassword />,
    },
  ],
};

export default SettingsRoutes;
