import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { IdleTimerProvider, useIdleTimerContext } from "react-idle-timer";

// material-ui
import { styled, useTheme, Theme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
  Slide,
  SlideProps,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";

// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import navigation from "menu-items";
import operations from "utils/api/superAgentWrapper";
import { drawerWidth } from "store/constant";
import { SET_MENU } from "store/actions";
import { DefaultRootStateProps } from "types";

// assets
import { IconChevronRight } from "@tabler/icons";
import Sidebar from "./Sidebar";
import Header from "./Header";
import useAuth from "../../hooks/useAuth";

interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

const Transition = React.forwardRef((props: SlideProps, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: -(drawerWidth - 20),
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
        marginRight: "10px",
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

function Child({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: any }) {
  const idleTimer = useIdleTimerContext();

  async function resetToken() {
    try {
      const res = await operations.post("admin/reset_token");
      const newToken = res.headers.authorization;
      localStorage.setItem("serviceToken", newToken.replace("Bearer ", ""));
      return res;
    } catch (error) {
      console.log("Error resetting token", error);
      return null;
    }
  }

  const handleTimerReset = () => {
    resetToken().then(() => {
      idleTimer.reset();
      setIsOpen(false);
    });
  };

  return (
    <>
      <Outlet />
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-slide-title1"
        aria-describedby="alert-dialog-slide-description1"
      >
        <DialogTitle id="alert-dialog-slide-title1">
          Are you still there?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description1">
            <Typography variant="body2" component="span">
              For your security, you will be logged out if you do not select to
              Continue.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pr: 2.5 }}>
          <Button variant="contained" size="medium" onClick={handleTimerReset}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function MainLayout() {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

  // Handle left drawer
  const leftDrawerOpened = useSelector(
    (state: DefaultRootStateProps) => state.customization.opened
  );
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useAuth();
  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  const onPrompt = () => {
    setIsOpen(true);
  };

  const onIdle = () => {
    logout();
  };

  const onActive = (event: any) => {
    // Close Modal Prompt
    // Do some active action
  };

  const onAction = (event: any) => {
    // Do something when a user triggers a watched event
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* TODO: Will need to update how the breadcrumbs highlight when going to a route not listed on the main nav */}
        {/*<Breadcrumbs*/}
        {/*  separator={IconChevronRight}*/}
        {/*  navigation={navigation}*/}
        {/*  icon*/}
        {/*  title*/}
        {/*  rightAlign*/}
        {/*/>*/}
        <IdleTimerProvider
          timeout={1000 * 3600}
          promptTimeout={1000 * 300}
          onPrompt={onPrompt}
          onIdle={onIdle}
          onActive={onActive}
          onAction={onAction}
        >
          <Child isOpen={isOpen} setIsOpen={setIsOpen} />
        </IdleTimerProvider>
      </Main>
    </Box>
  );
}

export default MainLayout;
