import React, { createContext, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
// Apollo-Client
import { gql, useQuery } from "@apollo/client";

// third-party
import { Chance } from "chance";
import jwtDecode from "jwt-decode";

// project imports
import Loader from "ui-component/Loader";
import axios from "utils/axios";
import { initialLoginContextProps, KeyedObject } from "types";
import { JWTContextType } from "types/auth";
import { UserProfile } from "_mockApis/user-profile/types";
import operations from "utils/api/superAgentWrapper";
import useSnackbar from "../hooks/useSnackbar";

// Init Chance
const chance = new Chance();

// constant
const initialState: initialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  adminUser: null,
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  let decoded: KeyedObject | null = null;
  try {
    decoded = jwtDecode(serviceToken);
  } catch {
    // if user attempts to enter a serviceToken manually and it's not valid, catch and return false on verify.
    return false;
  }
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded ? decoded.exp > Date.now() / 1000 : false;
};

const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export function JWTProvider({ children }: { children: React.ReactElement }) {
  const GET_USER = gql`
    query ($id: ID!) {
      adminUser(id: $id) {
        id
        firstName
        lastName
      }
    }
  `;
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();

  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data } = useQuery(GET_USER, {
    variables: { id: userId },
  });
  // TODO: This looks for an initial state for token.
  // We will need to modify this at some point to work
  // how we need it to. Hardcoded AdminUser credentials.

  useEffect(() => {
    const serviceToken = localStorage.getItem("serviceToken");
    console.log("token effect", localStorage.getItem("serviceToken"));
    if (serviceToken && verifyToken(serviceToken)) {
      setSession(serviceToken);
      setLoading(false);
      const localUser: UserProfile = jwtDecode(serviceToken);
      setUserId(localUser.user_data.id);
    } else {
      setSession(null);
      setUser(null);
      setLoading(false);
      localStorage.removeItem("serviceToken");
    }
  }, []);

  useEffect(() => {
    if (data) {
      setUser(data.adminUser);
    }
  }, [data]);

  const login = async (email: string, password: string) => {
    try {
      const response = await operations.post("admin/sign_in", {
        email,
        password,
      });
      setSession(response.headers.authorization.slice(7));
      setUser(response.body);
      return response.body;
    } catch (error) {
      setSnackbar({
        snackbarMessage:
          "We are having some trouble logging you in. Please enter the correct information or try again!",
        snackbarSeverity: "error",
      });
      return null;
    }
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    // todo: this flow needs to be recoded as it is not verified
    const id = chance.bb_pin();
    const response = await axios.post("/api/account/register", {
      id,
      email,
      password,
      firstName,
      lastName,
    });
    let users = response.data;

    if (
      window.localStorage.getItem("users") !== undefined &&
      window.localStorage.getItem("users") !== null
    ) {
      const localUsers = window.localStorage.getItem("users");
      users = [
        ...JSON.parse(localUsers!),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`,
        },
      ];
    }

    window.localStorage.setItem("users", JSON.stringify(users));
  };

  const logout = async () => {
    try {
      setSession(null);
      setUser(null);
      navigate("login", { replace: true });
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  if (loading) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
}

export default JWTContext;
