import ReactDOM from "react-dom";

// third party
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { Provider } from "react-redux";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
// import { onError } from "@apollo/client/link/error";
// import { ServerError } from "@apollo/client/link/utils";

// load mock apis
import "_mockApis";

// project imports
import * as serviceWorker from "serviceWorker";
import App from "App";
import { store } from "store";

// style + assets
import "assets/scss/style.scss";
import "antd/dist/antd.css";
import config from "config";
import history from "./utils/history";

// ==============================|| CONNECT GRAPHQL TO HOST  ||============================== //
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_HOST}/graphql?key=${process.env.REACT_APP_HUB_API_KEY}`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization:
        localStorage.getItem("serviceToken") !== null
          ? `Bearer ${localStorage.getItem("serviceToken")}`
          : null,
    },
  }));
  return forward(operation);
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const newToken = headers.get("Authorization");
      if (newToken) {
        // only wanting to use the token itself
        localStorage.setItem("serviceToken", newToken.replace("Bearer ", ""));
      }
    }
    return response;
  });
});

// const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
//   if (graphQLErrors) {
//     graphQLErrors.forEach(({ message, locations, path, extensions }) => {
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       );
//       if (message.includes("You need to authenticate to perform this action")) {
//         localStorage.removeItem("serviceToken");
//         history.replace("/");
//       }
//     });
//   }
//   if (networkError) {
//     console.log(`[Network error]: ${networkError}`);
//     if ((networkError as ServerError).statusCode === 401) {
//       localStorage.removeItem("serviceToken");
//       history.replace("/");
//     }
//   }
// })
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, afterwareLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
  },
});

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
  <HistoryRouter basename={config.basename} history={history}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
  </HistoryRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
