import { lazy } from "react";

// project imports
import MinimalLayout from "layout/MinimalLayout";
import Loadable from "ui-component/Loadable";

// Verify user podcast
const ClaimsWizard = Loadable(lazy(() => import("views/claims-wizard/index")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const ClaimRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "verifyyourpodcast",
      element: <ClaimsWizard />,
    },
  ],
};

export default ClaimRoutes;
