import vodacastLogo from "assets/images/vc-logo.svg";
import { styled } from "@mui/system";
// ==============================|| MAIN LOGO ||============================== //

function LogoSection() {
  const StyledLayoutLogo = styled("div")({
    maxHeight: 55,
    width: "70%",
    justifyContent: "center",
  });

  return (
    <StyledLayoutLogo>
      <div>
        <img src={vodacastLogo} alt="logo" />
      </div>
    </StyledLayoutLogo>
  );
}

export default LogoSection;
