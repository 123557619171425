import { lazy } from "react";

import Loadable from "ui-component/Loadable";
import { Outlet } from "react-router";

const UserManagementPage = Loadable(
  lazy(() => import("views/user-management/components/UserManagementTable"))
);

const UserManagementContainer = <Outlet />;

const UserManagementRoutes = {
  path: "user-management",
  element: UserManagementContainer,
  children: [
    {
      path: "",
      element: <UserManagementPage />,
    },
  ],
};

export default UserManagementRoutes;
