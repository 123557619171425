import { lazy } from "react";
import { Outlet } from "react-router-dom";

import Loadable from "ui-component/Loadable";

const PodcastsPage = Loadable(lazy(() => import("views/podcasts")));

const PodcastPage = Loadable(
  lazy(() => import("views/podcasts/components/Podcast"))
);
// ==============================|| PODCAST ROUTING ||============================== //
const PodcastsContainer = <Outlet />;

const PodcastsRoutes = {
  path: "podcasts",
  element: PodcastsContainer,
  children: [
    {
      path: "",
      element: <PodcastsPage />,
    },
    {
      path: ":id",
      element: <PodcastPage />,
    },
  ],
};

export default PodcastsRoutes;
