import request from "superagent";
import decodeJWT from "jwt-decode";
import config from "config";
import uploadFile from "./upload";

const key = "userToken";

function getValidToken() {
  const token = localStorage.getItem("serviceToken") || "";
  try {
    const decodedToken = decodeJWT(token);
    const now = Date.now() / 1000;
    // Check if token has expired
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (now > decodedToken.exp) {
      return null;
    }
    // Valid token
    return token;
  } catch (error) {
    // Invalid token
    return null;
  }
}

const post = (url: string, params = {}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  params.key = config.apiKey;
  return request
    .post(`${config.host}/${url}`)
    .send(params)
    .query({ key: config.apiKey })
    .set("Authorization", `Bearer ${getValidToken()}`)
    .accept("application/json");
};

const multi = (
  url: string,
  file: File,
  params = {
    content_params: "",
  }
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  params.key = config.apiKey;
  return request
    .post(`${config.host}/${url}`)
    .field("content_params", JSON.stringify(params.content_params))
    .attach("file", file, file.name)
    .query({ key: config.apiKey })
    .set("Authorization", `Bearer ${getValidToken()}`)
    .accept("application/json");
};

const get = (url: string, params = {}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  params.key = config.apiKey;
  return request
    .get(`${config.host}/${url}`)
    .query(params)
    .set("Authorization", `Bearer ${getValidToken()}`)
    .accept("application/json");
};

const deleteRequest = (url: string, params = {}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  params.key = config.apiKey;
  return request
    .delete(`${config.host}/${url}`)
    .query({ key: config.apiKey })
    .set("Authorization", `Bearer ${getValidToken()}`)
    .accept("application/json");
};

export default { post, multi, get, deleteRequest };
