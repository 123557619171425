import { FormattedMessage } from "react-intl";

import { IconBook, IconHome, IconQuestionMark, IconUsers } from "@tabler/icons";

const icons = {
  IconHome,
  IconBook,
  IconQuestionMark,
  IconUsers,
};

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    {
      id: "primary-navigation",
      type: "group",
      children: [
        {
          id: "home",
          class: "home",
          title: <FormattedMessage id="home" />,
          type: "item",
          url: "/",
          icon: icons.IconHome,
          breadcrumbs: false,
        },
        {
          id: "podcasts",
          title: <FormattedMessage id="podcasts" />,
          type: "item",
          url: "/podcasts",
          icon: icons.IconBook,
          breadcrumbs: true,
        },
        {
          id: "user-management",
          title: <FormattedMessage id="user-management" />,
          type: "item",
          url: "/user-management",
          icon: icons.IconUsers,
          breadcrumbs: false,
        },
        {
          id: "resources",
          title: <FormattedMessage id="resources" />,
          type: "item",
          url: "/resources",
          icon: icons.IconQuestionMark,
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default menuItems;
