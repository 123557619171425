import { useNavigate } from "react-router-dom";

// project imports
import useAuth from "hooks/useAuth";
import config from "config";
import { GuardProps } from "types";
import { useEffect } from "react";

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && localStorage.getItem("serviceToken")) {
      navigate(config.defaultPath, { replace: true });
    }
  }, [user, navigate]);

  return children;
};

export default GuestGuard;
