import MainLayout from "layout/MainLayout";
import AuthGuard from "utils/route-guard/AuthGuard";
import PodcastsRoutes from "./PodcastRoutes";
import ResourcesRoutes from "./ResourcesRoutes";
import UserManagementRoutes from "./UserManagementRoutes";
import SettingsRoutes from "./SettingsRoutes";
import HomeRoute from "./HomeRoute";
import EpisodeRoutes from "./EpisodeRoutes";

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    // PLEASE KEEP ROUTES ALPHABETICALLY FOR EASIER FINDING
    EpisodeRoutes,
    HomeRoute,
    PodcastsRoutes,
    ResourcesRoutes,
    SettingsRoutes,
    UserManagementRoutes,
  ],
};

export default MainRoutes;
