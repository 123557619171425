import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const HomePage = Loadable(lazy(() => import("views/homePage")));

const HomeRoute = {
  path: "/",
  element: <HomePage />,
};

export default HomeRoute;
