import React, { createContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { SnackbarProps } from "@mui/material/Snackbar/Snackbar";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";

interface Props extends SnackbarProps {
  setSnackbar: ({ message, severity }: any) => void;
}

const SnackbarContext = createContext<Props | null>(null);

export function SnackbarProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<any>("success");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const theme = useTheme();

  const snackbarSuccess = {
    color: theme.palette.success.light,
    backgroundColor: theme.palette.primary.main,
  };

  const snackbarError = {
    color: theme.palette.success.light,
    backgroundColor: theme.palette.error.light,
  };

  const snackbarTheme = () => {
    return severity === "success" ? snackbarSuccess : snackbarError;
  };

  const setSnackbar = ({
    snackbarMessage,
    snackbarSeverity,
  }: {
    snackbarMessage: string;
    snackbarSeverity: string;
  }) => {
    setMessage(snackbarMessage);
    setSeverity(snackbarSeverity);
    setIsOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ setSnackbar }}>
      {children}
      <Snackbar
        open={isOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
      >
        <Alert severity={severity} sx={snackbarTheme} color="success">
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}

export default SnackbarContext;
